const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  deepstreamServerUrl: process.env.REACT_APP_DEEPSTREAM_SERVER_URL,
  mediaUrl: process.env.REACT_APP_MEDIA_URL,
  cognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN,
  cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  cognitoRedirectPath: '/success',
  cookieExpirationInDays: 1,
  cookieName: 'CIRCLYAPP_REFRESH_TOKEN'
};

export default config;
