import config from '../config';
import { replaceLocation } from '../location';

export const goToLoginPage = (state = '') => {
  const { cognitoDomain } = getCognitoConfig();

  replaceLocation(`${cognitoDomain}/login?${queryStringForLogin(state)}`);
};

export const goToRegistrationPage = (state = '') => {
  const { cognitoDomain } = getCognitoConfig();

  replaceLocation(`${cognitoDomain}/signup?${queryStringForLogin(state)}`);
};

export const logout = () => {
  const { cognitoDomain, cognitoClientId } = getCognitoConfig();
  const logoutUrl = window.location.origin;

  replaceLocation(`${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${logoutUrl}`);
};

const queryStringForLogin = (state = '') => {
  const { cognitoClientId } = getCognitoConfig();
  const redirectUrl = `${window.location.origin}/success`;

  const queryParams = {
    response_type: 'code',
    client_id: cognitoClientId,
    redirect_uri: redirectUrl,
    state
  };

  return new URLSearchParams(queryParams).toString();
};

const getCognitoConfig = () => {
  const { cognitoDomain, cognitoClientId } = config;
  if (!cognitoDomain) {
    throw new Error('cognito domain is not set in config');
  }
  if (!cognitoClientId) {
    throw new Error('cognito client id is not set in config');
  }

  return { cognitoDomain, cognitoClientId };
};
