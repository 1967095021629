const { goToRegistrationPage } = require('../app/services/cognito/cognito');

(function () {
  'use strict';

  window.goToRegistrationPage = goToRegistrationPage;

  if (window.WOW) {
    // Initiate the wowjs animation library
    new WOW().init();
  }

  /*
  // iPad and iPod detection
  var isiPad = function () {
    return navigator.platform.indexOf('iPad') != -1;
  };

  var isiPhone = function () {
    return navigator.platform.indexOf('iPhone') != -1 || navigator.platform.indexOf('iPod') != -1;
  };
  */

  var goToTop = function () {
    $('#js-gotop').on('click', function (event) {
      event.preventDefault();
      $('html, body').animate(
        {
          scrollTop: $('html').offset().top
        },
        500
      );

      return false;
    });
  };

  // Window Scroll
  var windowScroll = function () {
    $(window).scroll(function () {
      console.log('scroll');
      var scrlTop = $(this).scrollTop(),
        goToTop = $('#js-gotop');

      if ($('#nav-toggle-cp').hasClass('active')) {
        // if mobile and open menu don't do
        return false;
      } else {
        var header = $('#cpheader');
        if (scrlTop > 70 && scrlTop <= 2000) {
          header.addClass('navbar-fixed-top slideInDown');
        } else if (scrlTop <= 70) {
          if (header.hasClass('navbar-fixed-top')) {
            header.addClass('navbar-fixed-top slideOutUp');
            setTimeout(function () {
              header.removeClass('navbar-fixed-top slideInDown slideOutUp');
            }, 100);
          }
        }
      }
      if (scrlTop > 500 && scrlTop <= 2000) {
        goToTop.addClass('visible');
      } else if (scrlTop <= 500) {
        goToTop.removeClass('visible');
      }
    });
  };

  // Document on load.
  $(function () {
    windowScroll();
    goToTop();
  });

  const page = $('body').attr('id');

  if (page === 'home') {
    document.addEventListener('DOMContentLoaded', function () {
      // array with texts to type in typewriter
      // var dataText = [ "data.","numbers.", "ideas.", "toughts.", "with Circly."];

      //var dataText = ['better', 'faster', 'together', 'with Circly'];
      var dataText = ['ideas', 'tasks', 'notes', 'projects', 'work', 'life', 'everyting...'];
      // var dataText = [];

      // type one text in the typwriter
      // keeps calling itself until the text is finished
      function typeWriter(text, i, fnCallback) {
        // chekc if text isn't finished yet
        if (i < text.length) {
          // add next character to h1
          // console.log(text.substring(0, i+1) +'<span aria-hidden="true"></span>')
          if (document.querySelector('#bla')) {
            document.querySelector('#bla').innerHTML =
              text.substring(0, i + 1) + '<span id="cursor" aria-hidden="true"></span>';
          }

          // wait for a while and call this function again for next character
          setTimeout(function () {
            typeWriter(text, i + 1, fnCallback);
          }, 150);
        }
        // text finished, call callback if there is a callback function
        else if (typeof fnCallback == 'function') {
          // call callback after timeout
          setTimeout(fnCallback, 700);
        }
      }
      // start a typewriter animation for a text in the dataText array
      function StartTextAnimation(i) {
        if (typeof dataText[i] == 'undefined') {
          setTimeout(function () {
            StartTextAnimation(0);
          }, 20000);
        }
        // check if dataText[i] exists
        if (dataText[i] && i < dataText.length - 1) {
          // text exists! start typewriter animation
          typeWriter(dataText[i], 0, function () {
            // after callback (and whole text has been animated), start next text
            StartTextAnimation(i + 1);
          });
        }
      }
      // start the text animation
      StartTextAnimation(0);
    });
  }

  $('#cta-btn').click(goToRegistrationPage);
  $('#cta-btn-signup').click(goToRegistrationPage);
  $('#cta-btn-signup-education').click(goToRegistrationPage);

  $('#home-link').on('click', function (event) {
    event.preventDefault();
    $('html, body').animate(
      {
        scrollTop: $('#cphome').offset().top
      },
      500
    );

    return false;
  });

  $('#home-link-mobile').on('click', function (event) {
    event.preventDefault();

    $('.menu-overlay').toggleClass('open');
    $('.menu').toggleClass('open');

    $('html, body').animate(
      {
        scrollTop: $('#cphome').offset().top
      },
      500
    );

    return false;
  });

  $('#product-link').on('click', function (event) {
    event.preventDefault();

    $('.menu-overlay').toggleClass('open');
    $('.menu').toggleClass('open');

    $('html, body').animate(
      {
        scrollTop: $('#cpproduct').offset().top
      },
      500
    );

    return false;
  });

  $('#pricing-link').on('click', function (event) {
    event.preventDefault();
    $('html, body').animate(
      {
        scrollTop: $('#cppricing').offset().top
      },
      500
    );

    return false;
  });

  $('#pricing-link-mobile').on('click', function (event) {
    event.preventDefault();

    $('.menu-overlay').toggleClass('open');
    $('.menu').toggleClass('open');

    $('html, body').animate(
      {
        scrollTop: $('#cppricing').offset().top
      },
      500
    );

    return false;
  });

  $('#product-link-mobile').on('click', function (event) {
    event.preventDefault();

    $('.menu-overlay').toggleClass('open');
    $('.menu').toggleClass('open');

    $('html, body').animate(
      {
        scrollTop: $('#cpproduct').offset().top
      },
      500
    );

    return false;
  });

  $('#contact-link').on('click', function (event) {
    event.preventDefault();
    $('html, body').animate(
      {
        scrollTop: $('#contact').offset().top
      },
      500
    );

    return false;
  });

  $('#contact-link-mobile').on('click', function (event) {
    event.preventDefault();

    $('.menu-overlay').toggleClass('open');
    $('.menu').toggleClass('open');

    $('html, body').animate(
      {
        scrollTop: $('#contact').offset().top
      },
      500
    );

    return false;
  });

  // mobile open menu
  $('.menu-link').click(function (e) {
    e.preventDefault();

    $('.menu-overlay').toggleClass('open');
    $('.menu').toggleClass('open');

    if ($('.menu-overlay').hasClass('open')) {
      $('#cpheader').addClass('navbar-fixed-top-mobile');
    } else {
      $('#cpheader').removeClass('navbar-fixed-top-mobile');
    }
  });
})();

var slideIndex = 1;
showSlides(slideIndex);

function showSlides(n) {
  var i;
  var slides = document.getElementsByClassName('mySlides');
  var dots = document.getElementsByClassName('dot');
  if (n > slides.length) {
    slideIndex = 1;
  }
  if (n < 1) {
    slideIndex = slides.length;
  }
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = 'none';
  }
  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(' active', '');
  }

  if (slides && slides[slideIndex - 1]) {
    slides[slideIndex - 1].style.display = 'block';
    dots[slideIndex - 1].className += ' active';
  }
}
